import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorMessageService {

  constructor(private toastr: ToastrService) { }

  showSuccess(message='', title='', config={})
  {
    this.toastr.success(message, title, config);
  }
  showError(message='', title='', config={})
  {
    this.toastr.error(message, title, config);
  }
}
