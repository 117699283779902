<!-- horizontal menu -->
<section>
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item">
            <a
                class="nav-link"
                routerLink="/backoffice/loans/overview/{{ id }}/loanInfo/{{
                    id
                }}"
                routerLinkActive="active"
                id="pills-leadinfo-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="pills-leadinfo"
                aria-selected="true"
                >Loan Info</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLink="/backoffice/loans/overview/{{ id }}/userInfo/{{
                    id
                }}"
                routerLinkActive="active"
                id="pills-userinfo-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="pills-userinfo"
                aria-selected="false"
                >User Info</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLink="/backoffice/loans/overview/{{ id }}/leadInfo/{{
                    id
                }}"
                routerLinkActive="active"
                id="pills-antifraud-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="pills-antifraud"
                aria-selected="false"
                >Lead info</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLink="/backoffice/loans/overview/{{ id }}/antiFraud/{{
                    id
                }}"
                routerLinkActive="active"
                id="pills-antifraud-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="pills-antifraud"
                aria-selected="false"
                >Anti-fraud</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLink="/backoffice/loans/overview/{{
                    id
                }}/scoringDetails/{{ id }}"
                routerLinkActive="active"
                id="pills-scoring-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="pills-scoring"
                aria-selected="false"
                >Scoring details</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLink="/backoffice/loans/overview/{{ id }}/docs/{{ id }}"
                routerLinkActive="active"
                id="pills-leadoc-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="pills-leadoc"
                aria-selected="false"
                >Docs</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLink="/backoffice/loans/overview/{{ id }}/smsLog/{{ id }}"
                routerLinkActive="active"
                id="pills-smslog-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="pills-smslog"
                aria-selected="false"
                >SMS log</a
            >
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                routerLink="/backoffice/loans/overview/{{ id }}/emailLog/{{
                    id
                }}"
                routerLinkActive="active"
                id="pills-emailog-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="pills-emailog"
                aria-selected="false"
                >Email log</a
            >
        </li>
        <app-operation-nav-bar
            [creditLine]="creditLine"
        ></app-operation-nav-bar>
        <app-tracking [creditline]="creditLine"></app-tracking>
    </ul>
</section>
<!-- horizontal menu end -->

<section>
    <div class="row">
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
            <lead>
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="card-header-title text-center">Loan</h5>
                    </div>
                    <div class="card-body">
                        <p class="text-center">{{ creditLine?.code_str }}</p>
                    </div>
                </div>
            </lead>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
            <status>
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="card-header-title text-center">STATUS</h5>
                    </div>
                    <div class="card-body">
                        <p class="text-center">
                            {{ creditLine?.status?.name }}
                        </p>
                    </div>
                </div>
            </status>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
            <dni>
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="card-header-title text-center">DNI</h5>
                    </div>
                    <div class="card-body">
                        <p class="text-center">{{ lead?.user?.personalid }}</p>
                    </div>
                </div>
            </dni>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
            <customer>
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="card-header-title text-center">
                            <div>CUSTOMER</div>
                        </h5>
                    </div>
                    <div class="card-body">
                        <p class="text-center">
                            {{ lead?.user?.name }}
                            {{ lead?.user?.first_surname }}
                            {{
                                lead?.user?.second_surname
                                    ? lead?.user?.second_surname
                                    : ''
                            }}
                        </p>
                        <div class="badges d-flex justify-content-center">
                            <span
                                *ngIf="lead?.user?.blacklisted"
                                class="badge badge-pill badge-dark"
                                >Blacklist</span
                            >
                            <span
                                *ngIf="lead?.user?.dropout"
                                class="badge badge-pill badge-warning"
                                >Drop out</span
                            >
                            <span
                                *ngIf="lead?.user?.is_asnef"
                                class="badge badge-pill badge-danger"
                                >ASNEF</span
                            >
                        </div>
                    </div>
                </div>
            </customer>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
            <mobile>
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="card-header-title text-center">Mobile</h5>
                    </div>
                    <div class="card-body">
                        <p
                            class="text-center"
                            [innerHTML]="formatPhone(lead?.mobile_phone)"
                        ></p>
                    </div>
                </div>
            </mobile>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
            <div *ngIf="debt_collector" class="card mb-3">
                <div class="card-header">
                    <h5 class="card-header-title text-center">
                        Debt Collector
                    </h5>
                </div>
                <div class="card-body">
                    <p class="text-center">{{ debt_collector || '–' }}</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--<app-lead-info-summary [lead]="lead" [user]="user" [lead_status]="leadStatus"></app-lead-info-summary>-->

<router-outlet></router-outlet>
