import { Component, OnInit } from '@angular/core';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { AsnefService } from '../../../services/asnef.service';

@Component({
    selector: 'app-setting-validation-asnef',
    templateUrl: './setting-validation-asnef.component.html',
    styleUrls: ['./setting-validation-asnef.component.scss']
})
export class SettingValidationAsnefComponent implements OnInit {
    forms: any[] = null;
    saving = false;
    validator_limit_debt_general = 1;
    validator_limit_debt_detail = 2;
    constructor(private errorMessage: ErrorMessageService, private asnefService: AsnefService) {}
    ngOnInit() {
        this.asnefService.getConfigValidatorList().subscribe(list => {
            if (list) {
                this.forms = list;
            }
        });
    }

    save() {
        this.saving = true;

        if (this.forms.length > 0) {
            this.forms.forEach(form => {
                this.asnefService.updateConfigValidator(form).subscribe(
                    resp => {
                        if (resp) {
                            this.errorMessage.showSuccess('El validador ha sido actualizado');
                        } else {
                            this.saving = false;
                            this.errorMessage.showError('No se puedo actualizar el validador');
                        }
                    },
                    error => {
                        this.saving = false;
                        this.errorMessage.showError(error.error.error.show_message);
                    }
                );
            });
        }

        this.saving = false;
    }
}
